<template>
  <div class="container">
    <div class="form-root">
      <div class="inputs-block">
        <div class="question">
          <img
            src="~/assets/img/svg/form/question.svg"
            alt="question"
          >
        </div>
        <div class="title">{{ title }}</div>
        <div class="callback">
          <span>{{ message }}</span>
          <div @click="()=>isShowDatePicker = true">
            <img
              src="~/assets/img/svg/form/clock.svg"
              alt="clock"
            >
            {{ timeForCallTitle }}
          </div>
        </div>
        <div class="input-group__top">
          <div class="send-block">
            <div class="inputs-flex">
              <div class="input-group">
                <input
                  id="input-name"
                  v-model="state.name"
                  class="input"
                  type="text"
                  placeholder="Имя"
                />
                <div
                  v-for="(error, index) in $v.name.$errors"
                  :key="index"
                  class="form-error custom-error"
                >
                  {{ error.$message }}
                </div>
              </div>
              <div class="input-group">
                <input
                  id="input-tel"
                  v-model="state.phone"
                  v-maska
                  class="input"
                  data-maska="+7(###) ###-##-##"
                  type="tel"
                  placeholder="Телефон или мессенджер"
                />
                <div
                  v-for="(error, index) in $v.phone.$errors"
                  :key="index"
                  class="form-error custom-error"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>
            <div class="btnWrapper --desc">
              <button
                class="btn primary --shadow"
                :disabled="isFormSending || !isAgreeWithPolitics"
                @click="sendForm"
              >
                Отправить
              </button>
              <div class="checkbox-wrapper">
                <span class="common-term-accept label-condition">
                  Нажимая на кнопку, Вы соглашаетесь<br />
                  <a
                    class="v-form_politics-link"
                    href="/privacy"
                    target="_blank"
                  >
                    с политикой
                    конфиденциальности
                  </a>
                </span>
              </div>
            </div>
          </div>
          <textarea
            v-model="state.message"
            class="textarea"
            placeholder="Ваш вопрос"
          ></textarea>
          <div class="btnWrapper --mob">
            <button
              class="btn primary --shadow"
              :disabled="isFormSending || !isAgreeWithPolitics"
              @click="sendForm"
            >
              Отправить
            </button>
            <div class="checkbox-wrapper">
              <span class="common-term-accept label-condition">
                Нажимая на кнопку, Вы соглашаетесь<br />
                <a
                  class="v-form_politics-link"
                  href="/privacy"
                  target="_blank"
                > с политикой
                  конфиденциальности</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DateAndTimePopup
    v-if="isShowDatePicker"
    :current-time="callbackTime"
    @onClose="onCloseDatePicker"
  />
</template>

<script setup lang="ts">
import { helpers, minLength, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { reactive } from '#imports';
import type { FormSendType } from '~/types/Commons';
import type { DateAndTimePopupType } from '~/components/popups/DateAndTimePopup.vue';
import { metricsHelper } from '~/utils/forms/metricsHelper';
import parseUtmSessionStorage from "~/utils/forms/parseUtmSessionStorage";

const DateAndTimePopup = defineAsyncComponent(() => import('~/components/popups/DateAndTimePopup.vue'));

const { imageId, title, message, type, ym } = defineProps<{
	imageId: number,
	title?: string,
	message?: string,
	type?: string;
	ym?: string;
}>();

const isFormSending = ref<boolean>(false);
const isAgreeWithPolitics = ref<boolean>(true);

const state = reactive<FormSendType>({
  name: '',
  phone: '',
  message: '',
  type: type || 'Обратный звонок'
});

const rules = {
  name: {
    required: helpers.withMessage('Обязательно к заполнению', required)
  },
  phone: {
    required: helpers.withMessage('Обязательно к заполнению', required),
    minLength: helpers.withMessage('Неверный формат', minLength(17))
  }
};

const { $api, $toast } = useNuxtApp();
const $route = useRoute();
const $v = useVuelidate(rules, state);

const isShowDatePicker = ref<boolean>(false);
const callbackTime = ref<DateAndTimePopupType | null>(null);
const timeForCallTitle = computed(() => (callbackTime.value
  ? `${callbackTime.value?.date} в ${callbackTime.value?.time.label}`
  : 'Указать время звонка'));

const onCloseDatePicker = (time: DateAndTimePopupType | null) => {
  isShowDatePicker.value = false;
  callbackTime.value = time;
};

const sendForm = async () => {
  const isFormCorrect = await $v.value.$validate();
  if (isFormCorrect) {
    isFormSending.value = true;

    const newForm = { ...state };

    newForm.url = `${window.location.host}${$route.fullPath}`;
    newForm.message = `${state.message} \nПрошу перезвонить мне ${callbackTime.value?.date || 'как можно скорее'} в
    ${callbackTime.value?.time.value || 'как можно скорее'}.\nИмя: ${newForm.name}.\nТелефон: ${newForm.phone}.`;
    newForm.additional_data = parseUtmSessionStorage()

    const response = await $api.agent.post('/form/send', { body: { ...newForm } })
      .then((res) => res._data)
      .catch((err) => ({ error: errorParserServerResponse(err.response) }));

    if (response.error) {
      $toast.error(response.error);
      isFormSending.value = false;
      return false;
    }

    $toast.success('Ваша заявка успешно отправлена');

    metricsHelper({
      goal: ym || '',
      phone: newForm?.phone,
      name: newForm?.name
    });

    isFormSending.value = false;

    callbackTime.value = null;

    state.name = '';
    state.phone = '';
    state.message = '';
    $v.value.$reset();

    return true;
  }
};

</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.form-root {
	border-radius: 14px;
	border: 3px solid var(--secondary);
	background: var(--primary-10);
	box-shadow: 0 20px 40px 0 rgb(0 0 0 / 5%);
	padding: 48px 109px;
	display: flex;
	gap: 95px;
}

.inputs-block {
	width: 100%;
	display: flex;
	flex-direction: column;

	& .btnWrapper {
		position: relative;

		&.--mob {
			display: none;
		}

		& > button {
			width: 100%;
			margin-bottom: 16px;
		}
	}

	& > .input-group__top {
		display: flex;
		gap: 30px;

		& textarea, input {
			font-size: 20px;
			font-weight: 400;
			border-color: rgb(60 29 109 / 20%);

			&::placeholder {
				color: var(--primary-40);
			}
		}

		& > textarea {
			resize: none;
			min-height: 134px;
		}
	}

	& > .question {
		margin-bottom: 24px;
		display: none;
		width: 69px;
		height: 69px;
		border-radius: 50%;
		justify-content: center;
		align-items: center;
		background: var(--white);
		box-shadow: 0 0 0 0 rgb(0 0 0 / 4%), 0 2px 4px 0 rgb(0 0 0 / 4%), 0 7px 7px 0 rgb(0 0 0 / 4%), 0 16px 9px 0 rgb(0 0 0 / 3%), 0 28px 11px 0 rgb(0 0 0 / 1%), 0 44px 12px 0 rgb(0 0 0 / 0%);
	}

	& > .title {
		color: var(--primary);
		font-size: 36px;
		font-style: normal;
		font-weight: 600;
		line-height: 130%;
		letter-spacing: -0.72px;
		margin-bottom: 26px;
	}

	& > .callback {
		display: flex;
		gap: 24px;
		align-items: center;
		margin-bottom: 34px;

		& > span {
			color: var(--primary-80);
			font-size: 24px;
			font-style: normal;
			font-weight: 400;
			letter-spacing: -0.48px;
			width: fit-content;
		}

		& > div {
			display: flex;
			gap: 6px;
			align-items: center;
			border-radius: 45px;
			padding: 6px 14px;
			cursor: pointer;
			border: 1px solid var(--primary-20);
			transition: opacity .2s linear;
			color: var(--primary);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 16px; /* 100% */
			letter-spacing: -0.16px;

			&:hover {
				opacity: .7;
			}
		}
	}
}

.send-block {
	width: 100%;
	max-width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	& .inputs-flex {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-bottom: 20px;
	}

	& > .imgWrapper {
		height: 284px;
		border-radius: 10px;

		& > img {
			height: 100%;
			width: 100%;
			position: absolute;
		}
	}
}

.checkbox-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.common-term-accept {
	display: flex;
	align-items: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%;
	color: var(--primary-60);

	.checkbox {
		width: 20px;
		min-width: 20px;
		height: 20px;
		min-height: 20px;
		border-radius: 4px;
		border-width: 1px;
		margin-right: 5px;
		opacity: .8;
	}

	a {
		display: contents;
		font-weight: 600;
	}
}

.custom-error {
	position: absolute !important;
}

@include media('lg') {
	.form-root {
		flex-direction: column;
		gap: 30px;
	}

	.inputs-block, .send-block {
		width: 100%;
	}

	.imgWrapper {
		display: none;
	}

	.checkbox-wrapper {
		position: static;
		transform: none;
		margin-top: 15px;
	}
}

@include media('lg') {
	.form-root {
		align-items: center;
		gap: 20px;
	}

	.inputs-block {
		align-items: center;

		& > .title {
			text-align: center;
		}

		& > .callback {
			flex-direction: column;
			align-items: center;

			& > span {
				text-align: center;
				margin-bottom: 20px;
			}
		}

		& > .inputs-flex {
			flex-direction: column;
			gap: 20px;
			width: 100%;

			& > .input-group {
				max-width: 100%;
			}
		}
	}
}

@include media('md') {
	.form-root {
		padding: 26px 20px;
	}

	.inputs-block {
		& .btnWrapper {
			position: relative;

			&.--mob {
				display: flex;
				flex-direction: column;
				margin-top: 24px;

				& > button {
					margin-bottom: 0;
				}
			}

			&.--desc {
				display: none;
			}
		}

		& > .question {
			display: flex;
		}

		& > .title {
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 130%;
			letter-spacing: -0.4px;
			margin-bottom: 18px;
		}

		& > .callback {
			margin-bottom: 20px;
			gap: 14px;

			& > span {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%;
				margin-bottom: 0;
			}

			& > div {
				font-size: 13px;
				font-style: normal;
				font-weight: 600;
			}
		}

		& > .input-group__top {
			flex-direction: column;
			width: 100%;
			gap: 0;
		}
	}

	.send-block {
		max-width: 100%;
	}
}

@include media('sm') {

}
</style>
